<template>
  <Stockview>
    <div class="columns is-vcentered is-multiline" v-if="listings">
      <div class="column is-12-touch is-narrow">
        <p class="is-size-7-mobile">
          <span class="icon is-small">
            <i class="fal fa-tags" />
          </span>
          <span>
            Viewing {{ formatNumber(listings.count) }} vehicles currently
            on&nbsp;sale
          </span>
        </p>
      </div>
      <div class="column is-12-touch">
        <p class="is-size-7-mobile">
          <span class="icon is-small">
            <i class="fal fa-info-circle"></i>
          </span>
          <span>
            Average price of {{ pounds(listings.averagePrice) }}
          </span>
        </p>
      </div>

      <div class="column is-12-touch is-narrow warning-toggle">
        <p class="is-size-7 has-text-primary">Warning vehicles only</p>
        <WarningToggle />
      </div>
      <div class="column is-12-touch is-narrow">
        <StockviewSearch />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless" />
      </div>
    </div>
    <div>
      <DataTable
        @sort="sort"
        @resizeColumn="resizeColumn"
        @toggleColumn="toggleColumn"
        @setPageSize="setPageSize"
        v-bind="{ sortBy, sortOrder, columns: filteredColumns, rows: listings.items, pageSize }"
        v-if="listings"
      >
        <template v-slot:filter>
          <StockviewFilters v-bind="{ listings }" />
        </template>
        <template v-slot:pagination>
          <pagination
            :fn="'loadListings'"
            v-bind="{ page: filters.page, pages: listings.pageCount }"
            @change="changePage"
          />
        </template>
      </DataTable>
    </div>
  </Stockview>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { pounds } from 'core/utils/format'
import { formatNumber } from 'accounting'
export default {
  name: 'StockviewStock',
  components: {
    Pagination: () => import('core/modules/pagination/components/Pagination'),
    Stockview: () => import('./Stockview'),
    WarningToggle: () => import('../components/StockviewWarningToggle'),
    StockviewSearch: () => import('../components/StockviewSearch'),
    StockviewFilters: () => import('../components/StockviewFilters'),
    DataTable: () => import('modules/data-table/components/DataTable')
  },
  async mounted() {
    this.$mxp.track('stockview_stock_page_load')
    if (!this.summary) await this.load()
    if (this.sellerId && !this.listings) await this.loadListings()
  },
  computed: {
    ...mapGetters('stockview/listings', [
      'listings',
      'sortBy',
      'sortOrder',
      'columns',
      'pageSize',
      'filters'
    ]),
    ...mapGetters('stockview/dashboard', ['summary', 'sellerId']),
    ...mapGetters('auth', ['permissions']),
    filteredColumns() {
      if (!this.permissions.marketDemand) {
        return this.columns.filter(({ id }) => id !== 'desirability')
      }

      return this.columns
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['setPageSize']),
    ...mapActions('stockview/listings', ['setColumnOverride']),
    changePage(page) {
      this.setFilter({ type: 'page', payload: page })
      this.loadListings()
    },
    sort($event) {
      this.setSort($event)
      this.loadListings()
    },
    resizeColumn({ id, width }) {
      this.setColumnOverride({ id, key: 'width', value: width })
    },
    toggleColumn({ id, display }) {
      this.setColumnOverride({ id, key: 'display', value: display })
    },
    formatNumber,
    pounds,
    ...mapActions('stockview/listings', ['loadListings']),
    ...mapActions('stockview/dashboard', ['load']),
    ...mapMutations('stockview/listings', ['setSort', 'setFilter'])
  }
}
</script>

<style lang="css" scoped>
.warning-toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
